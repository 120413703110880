import {Box, Paper, Stack, Typography} from '@mui/material';
import {iTimedMessageMessageItem} from '../contents/TimedMsgRequestContentMessageCard';
import {IMediaTrack} from '../../../../../dto/MediaTrack.dto';
import MusicPreviewBtn from '../../../../../components/music/MusicPreviewBtn';
import DownloadButton from '../../../../../components/Button/DownloadButton';

type Props = {
  type: string;
  messages: iTimedMessageMessageItem[];
};

export const ContentItemTrack = ({
  track,
  id,
}: {
  track: IMediaTrack;
  id: number;
}) => {
  return (
    <Box className='flex text-white gap-2 items-center my-1'>
      {track.url && (
        <Stack direction='row' spacing={2}>
          <MusicPreviewBtn id={id} url={track.url} />{' '}
          <DownloadButton filename={track.track_name} url={track.url} />
        </Stack>
      )}
      <Typography variant='body2'>
        Artist:{' '}
        <Typography component='span' variant='subtitle2'>
          {track.track_artist}
        </Typography>
      </Typography>
      <Typography variant='body2'>
        Title:{' '}
        <Typography component='span' variant='subtitle2'>
          {track.track_name}
        </Typography>
      </Typography>
    </Box>
  );
};

const ContentItemNewTrack = ({
  artist,
  title,
}: {
  artist: string;
  title: string;
}) => {
  return (
    <>
      <Typography variant='body2'>
        Artist:{' '}
        <Typography component='span' variant='subtitle2'>
          {artist}
        </Typography>
      </Typography>
      <Typography variant='body2'>
        Title:{' '}
        <Typography component='span' variant='subtitle2'>
          {title}
        </Typography>
      </Typography>
      <Typography flexGrow={1} align='right' variant='caption'>
        New song request. we will review it
      </Typography>
    </>
  );
};

const ContentItemUploadedFile = ({fileName}: {fileName: string}) => {
  return (
    <>
      <Typography variant='body2'>
        File Name:{' '}
        <Typography component='span' variant='subtitle2'>
          {fileName}
        </Typography>
      </Typography>
      <Typography flexGrow={1} align='right' variant='caption'>
        Uploaded song from your computer. we will review it
      </Typography>
    </>
  );
};

const ContentItem = ({item}: {item: iTimedMessageMessageItem}) => {
  let comp = <></>;

  if (item.type === 'song') {
    if (item.file) {
      comp = <ContentItemUploadedFile fileName={item.file.name} />;
    } else {
      comp = <ContentItemNewTrack artist={item.artist} title={item.title} />;
    }
  }
  if (item.message) {
    comp = (
      <Typography>
        <pre>{item.message}</pre>
      </Typography>
    );
  }
  if (item.track) {
    comp = <ContentItemTrack track={item.track} id={item.id} />;
  }
  return (
    <Paper>
      <Typography sx={{width: 100}}>
        {item.type === 'song' ? 'Song' : 'Message'}
      </Typography>
      <Stack direction='row' spacing={2} flexGrow={1} color='#ffffffa6'>
        {comp}
      </Stack>
    </Paper>
  );
};

const TimedMsgRequestReviewContents = ({messages}: Props) => {
  return (
    <Stack spacing={1} id={`message-song-details`}>
      {messages.map(item => (
        <ContentItem item={item} key={item.id} />
      ))}
    </Stack>
  );
};

export default TimedMsgRequestReviewContents;
