import {IconButton} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import {UserCanAny} from '../UserCan';

type Props = {
  filename: string;
  url: string;
};
const DownloadButton = ({filename, url}: Props) => {
  return (
    <UserCanAny scope='is-admin,staff'>
      <IconButton
        component='a'
        href={url}
        target='_blank'
        download={filename}
        rel='noreferrer'>
        <DownloadIcon />
      </IconButton>
    </UserCanAny>
  );
};

export default DownloadButton;
