import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {
  DataGrid,
  GridAlignment,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  IconButton,
  TextField,
  Button,
  Typography,
  Container,
  Alert,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import {useMutation} from '@tanstack/react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {
  IStudentMusicSelectQueryParms,
  useRqListMusicsInGenresByStudentToken,
} from '../../../react_query/music_selections/studentSelections/ListMusicsInGenresByToken';
import {useStylesed} from '../../../components/styles/commonStyled';
import {DataGridCustPagination} from '../../../components/utlis/DataGrid/Pagination';
import Toast from '../../../package/Toaster';
import {
  IStoreStudentSelectedSongs,
  StoreStudentSelectedSongs,
} from '../../../react_query/music_selections/studentSelections/StoreStudentSelectedSongs';
import {IMediaTrack} from '../../../dto/MediaTrack.dto';
import {VerifyStudentToken} from '../../../react_query/music_selections/studentSelections/VerifyStudentToken';
import {useRqUnauthStudentFormDetailsByStudentToken} from '../../../react_query/music_selections/studentSelections/UnauthStudentFormdetails';
import MusicPreviewBtn from '../../../components/music/MusicPreviewBtn';
import InputSearch from '../../../components/styles/InputSearch';
import {SongSelectionRemoveButton} from '../../../components/Button/SongSelectionRemoveButton';
import {SongSelectionAddButton} from '../../../components/Button/SongSelectionAddButton';
import {GridDurationColumn} from '../../../components/grid/GridColumns';

const StudentMusicSelection = () => {
  const {token} = useParams();
  const navigate = useNavigate();
  const classes = useStylesed();
  const [loading, setLoading] = useState(false);
  const [songLimit, setSongLimit] = useState(0);
  const [search, setSearch] = React.useState('');
  const [studentName, setStudentName] = React.useState('');
  const [selectedSongs, setSelectedSongs] = React.useState<IMediaTrack[]>([]);
  const [selectedSongIds, setSelectedSongIds] = React.useState<number[]>([]);
  const [pagination, setPagination] = React.useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const [query, setQuery] = React.useState<IStudentMusicSelectQueryParms>({
    page: 1,
    per_page: 10,
    search: '',
  });

  const {data, isFetching} = useRqListMusicsInGenresByStudentToken(
    token ?? '',
    query,
  );

  const {
    data: formData,
    isFetching: formIsFetching,
    isError: formIsError,
  } = useRqUnauthStudentFormDetailsByStudentToken(token ?? '');

  const handleSongAdd = React.useCallback((song: IMediaTrack) => {
    setSelectedSongIds(prev => {
      return [...prev, song.id];
    });
    setSelectedSongs(prev => {
      return [...prev, song];
    });
  }, []);

  const handleSongRemove = React.useCallback((song: IMediaTrack) => {
    setSelectedSongIds(prev => {
      return prev.filter(id => id !== song.id);
    });
    setSelectedSongs(prev => {
      return prev.filter(prevSong => prevSong.id !== song.id);
    });
  }, []);

  const columns: GridColDef[] = React.useMemo(
    () =>
      [
        {
          field: 'url',
          type: 'actions',
          headerName: 'Play',
          width: 54,
          align: 'center' as GridAlignment,
          renderCell: (params: GridRenderCellParams) =>
            params.row.url ? (
              <MusicPreviewBtn id={params.row.id} url={params.row.url} />
            ) : null,
        },
        {
          field: 'track_artist',
          headerName: 'Artist Name',
          flex: 1,
          align: 'left' as GridAlignment,
        },
        {
          field: 'track_name',
          headerName: 'Song title',
          hideable: false,
          flex: 1,
          align: 'left' as GridAlignment,
        },
        {
          ...GridDurationColumn,
          align: 'center' as GridAlignment,
        },
        {
          field: 'edit',
          headerName: 'Manage',
          hideable: false,
          align: 'center' as GridAlignment,
          headerAlign: 'center' as GridAlignment,
          renderCell: (params: GridRenderCellParams) => {
            return selectedSongIds?.includes(params.row.id) ? (
              <SongSelectionRemoveButton
                onClick={() => handleSongRemove(params.row)}
              />
            ) : (
              <SongSelectionAddButton
                onClick={() => handleSongAdd(params.row)}
              />
            );
          },
        },
      ].map(column => ({...column, sortable: false, filterable: false})),
    [handleSongAdd, handleSongRemove, selectedSongIds],
  );

  const store = useMutation({
    mutationFn: (payload: IStoreStudentSelectedSongs) => {
      return StoreStudentSelectedSongs(token ?? '', payload);
    },
    onSuccess: response => {
      Toast(response?.message, 'success');
      navigate(`/student-selection/${token}/success-msg`);
    },
    onSettled() {
      setLoading(false);
    },
  });

  const handleSubmit = React.useCallback(() => {
    setLoading(true);
    const selected_songs = selectedSongs.map(song => {
      return {
        media_track_id: song.id,
        track_artist: song.track_artist,
        track_name: song.track_name,
      };
    });
    store.mutate({
      name: studentName,
      selected_songs,
    });
  }, [selectedSongs, store, studentName]);

  useEffect(() => {
    if (!formIsFetching && !formIsError && formData) {
      setSongLimit(formData.song_count);
    }
  }, [formData, formIsError, formIsFetching]);

  React.useEffect(() => {
    setQuery(prevVal => ({
      ...prevVal,
      page: pagination.page + 1,
      per_page: pagination.pageSize,
      search: search,
    }));
  }, [pagination.page, pagination.pageSize, search]);

  React.useEffect(() => {
    const fetchData = async () => {
      const verifyStatus = await VerifyStudentToken(token ?? '');
      if (verifyStatus !== 200)
        navigate(`/student-selection/${token}/error-msg`);
    };
    fetchData();
  }, [navigate, token]);

  return (
    <Container
      sx={{background: 'var(--blue-variant6)'}}
      maxWidth='xl'
      className='radius-10 text-center my-10 pb-10'>
      <Header />
      <StudentNameCmpt
        studentName={studentName}
        setStudentName={setStudentName}
      />
      <Grid container>
        <Grid item xs={12} md={8} className='pr-4'>
          <Alert severity='warning' className='my-3'>
            Max {songLimit} songs can select ({selectedSongIds.length} songs
            selected) {!studentName ? <>& Student name is required</> : null}
          </Alert>
          <Box className={classes.r_between} mt={2}>
            {' '}
            <Typography variant='h5' align='center' mt={2} mb={1}>
              Songs List
            </Typography>
            <SearchBoxCmpt search={search} setSearch={setSearch} />
          </Box>
          <Box className='min-h-200 max-h-400 mt-2'>
            <DataGrid
              sx={{maxHeight: 400}}
              className='bg-transparent scrollbar overflow-auto'
              rows={data?.data ?? []}
              columns={columns}
              loading={isFetching}
              rowCount={data?.meta.total ?? 0}
              pageSizeOptions={[10, 25, 50]}
              paginationModel={pagination}
              paginationMode='server'
              onPaginationModelChange={setPagination}
              disableRowSelectionOnClick
              disableColumnMenu
              slots={{
                pagination: DataGridCustPagination,
              }}
              slotProps={{
                columnsPanel: {
                  disableHideAllButton: true,
                  disableShowAllButton: true,
                },
              }}
            />
          </Box>
        </Grid>
        <Grid
          className='radius-10'
          item
          xs={12}
          md={4}
          sx={{background: 'var(--blue_variant29)'}}>
          <Typography variant='h5' className='text-center my-5'>
            Song Requests List
          </Typography>
          <List
            className='list-items-sec overflow-auto scrollbar m-2 pr-4'
            sx={{maxHeight: 450}}>
            {selectedSongs.map(song => (
              <ListSelectedSongCmpt
                song={song}
                handleSongRemove={handleSongRemove}
                key={song.id}
              />
            ))}
          </List>
        </Grid>
      </Grid>
      <Box className={`${classes.r_center} mt-5`}>
        <Button
          variant='contained'
          className='min-w-150'
          size='large'
          onClick={handleSubmit}
          disabled={
            selectedSongIds.length < 1 ||
            selectedSongIds.length > songLimit ||
            studentName === '' ||
            loading
          }>
          Submit
        </Button>
      </Box>
    </Container>
  );
};

export default StudentMusicSelection;

function ListSelectedSongCmpt({
  song,
  handleSongRemove,
}: Readonly<{
  song: IMediaTrack;
  handleSongRemove: (song: IMediaTrack) => void;
}>) {
  const handleDelete = React.useCallback(() => {
    handleSongRemove(song);
  }, [handleSongRemove, song]);
  return (
    <ListItem key={song.id}>
      <ListItemText
        primary={`Arsitst Name : ${song.track_artist}`}
        secondary={`Song Title : ${song.track_name}`}
      />
      <ListItemIcon>
        <IconButton
          aria-label='delete-song'
          onClick={handleDelete}
          color='error'>
          <DeleteIcon />
        </IconButton>
      </ListItemIcon>
    </ListItem>
  );
}

function Header() {
  return (
    <Typography variant='h3' className='py-5 text-center font-bold'>
      STUDENT SELECTION FORM
    </Typography>
  );
}

const studentSchema = Yup.string()
  .max(250)
  .test('not-only-spaces', 'Value cannot contain only spaces', value => {
    if (value) {
      return /\S/.test(value);
    }
    return true;
  });

function StudentNameCmpt({
  studentName,
  setStudentName,
}: Readonly<{
  studentName: string;
  setStudentName: React.Dispatch<React.SetStateAction<string>>;
}>) {
  const classes = useStylesed();
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState('');

  const handleOnChange = React.useCallback(() => {
    if (inputRef.current) {
      const studentInput = inputRef.current.value;
      studentSchema
        .validate(studentInput, {abortEarly: false})
        .then(() => {
          setError('');
          setStudentName(studentInput);
        })
        .catch((err: Yup.ValidationError) => {
          setError(err.message);
        });
    }
  }, [setStudentName]);

  return (
    <Box className={classes.r_start}>
      <Typography
        fontWeight={500}
        sx={{
          mr: 2,
        }}>
        Student Name
      </Typography>
      <TextField
        className='max-w-400 w-full'
        type='text'
        variant='outlined'
        size='small'
        id='student-name'
        value={studentName}
        inputRef={inputRef}
        helperText={error}
        error={Boolean(error)}
        onChange={handleOnChange}
      />
    </Box>
  );
}

function SearchBoxCmpt({
  search,
  setSearch,
}: Readonly<{
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}>) {
  const handleSearch = React.useCallback(
    (val: string) => {
      setSearch(val);
    },
    [setSearch],
  );

  return (
    <InputSearch
      className='max-w-347 mr-6'
      placeholder='Search Song'
      value={search}
      onChange={handleSearch}
    />
  );
}
