import dayjs, {Dayjs} from 'dayjs';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import isBetween from 'dayjs/plugin/isBetween';
import {useQueryClient} from '@tanstack/react-query';
import {Box, Typography} from '@mui/material';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  StepperLayout,
  useStepperState,
} from '../../../components/layouts/stepper/StepperLayout';
import {
  companyIdSelector,
  isGTSelector,
} from '../../../store/Slices/companySlice';
import {apiPost} from '../../../axiosConfig';
import {IEventType} from '../../../dto/EventType.dto';
import {ICompanySchool} from '../../../dto/CompanySchool.dto';
import StationsDropDown from '../../../components/models/stations/StationDropDown';
import {IStation} from '../../../dto/Station.dto';
import EsmcDatesTimes from './EsmcDatesTimes';
import {IMsgReqEventDateType} from '../../../dto/MessageRequest.dto';
import {EsmMessages} from './EsmMessages';
import {IMessage} from '../../../dto/Message.dto';
import EsmReview from './EsmReview';
import DialogApiRequestStatus from '../../../components/dialogs/DialogApiRequestStatus';
import SchoolsDropdownGetSchools from '../../../components/models/company-schools/SchoolsDropdownGetSchools';
import EventsDropdownGetEvents from '../../../components/models/event/EventsDropdownGetEvents';
import {daysOfWeek, IStartEndDateTime} from '../../../dto/DateTime.dto';
import {IMediaTrack} from '../../../dto/MediaTrack.dto';
import {isInternalSelector} from '../../../store/Slices/userSlice';
import {eventSpecificDataGridApiPath} from '../../../react_query/messages/message-requests/EventMessageRequestList';
import {
  addNewTrackToMessages,
  updateTrackToMessage,
} from './MessagesStateFunctionality';
import ExistingCampaignChooserBtn from '../../sponsor/ExistingCampaignChooserBtn';
import {checkMessagesValid} from '../../../Service/CommonService';

dayjs.extend(isBetween);

const defaultApiState = {
  status: '',
  error: null,
  successMsg: '',
};

export type IScripts = Pick<IMessage, 'id' | 'requestable_type' | 'message'> & {
  uuid: string;
  name: string;
  media_track?: IMediaTrack;
};

export type IStartEndTime = {start_time: Dayjs | null; end_time: Dayjs | null};

const EventSpecificStepper = ({
  handleClose,
  defaultDays,
  defaultMessages,
  defaultEvents,
  defaultSchools,
  defaultStations,
  defaultEventDateType,
  defaultMultipleDates,
  base_request_id,
  update_id,
  messageStatus,
}: {
  handleClose: () => void;
  defaultDays: string[];
  defaultEvents: IEventType[];
  defaultSchools: ICompanySchool[];
  defaultStations: IStation[];
  defaultEventDateType: IMsgReqEventDateType;
  defaultMultipleDates: IStartEndDateTime[];
  defaultMessages: IScripts[];
  base_request_id?: number;
  update_id?: number;
  messageStatus: string | null;
}) => {
  const isStaff = useSelector(isInternalSelector);
  const navigate = useNavigate();
  const {activeStepIndex, onNextStep, onPreviousStep, onStepClick} =
    useStepperState(0);
  const companyId = useSelector(companyIdSelector);
  const [apiState, setApiState] = useState(defaultApiState);
  const [events, setEvents] = useState(defaultEvents);
  const [schools, setSchools] = useState(defaultSchools);
  const [stations, setStations] = useState(defaultStations);
  const [othersEventName, setOthersEventName] = useState<string>('');
  const [eventDateType, setEventDateType] = useState(defaultEventDateType);
  const [multipleDates, setMultipleDates] = useState(defaultMultipleDates);
  const [isPastDate, setIsPastDate] = useState(false);
  const [isPastTime, setIsPastTime] = useState(false);
  const [days, setDays] = useState(defaultDays);
  const [savedOldDays, setSavedOldDays] = useState<string[]>([]);
  const [messages, setMessages] = useState<IScripts[]>(defaultMessages);
  const [selectedTracks, setSelectedTracks] = useState<IMediaTrack[]>([]);
  const [allEvents, setAllEvents] = useState<IEventType[]>([]);

  const isGT = useSelector(isGTSelector);
  const queryClient = useQueryClient();

  const isEmptyDate = useMemo(() => {
    const today = isStaff
      ? dayjs().subtract(1, 'day').startOf('day')
      : dayjs().startOf('day');
    return multipleDates.every(date => {
      const startDate = dayjs(date.start_date).startOf('day');
      const endDate = dayjs(date.end_date).startOf('day');
      const startTime = date.start_time ? dayjs(date.start_time) : null;
      const endTime = date.end_time ? dayjs(date.end_time) : null;

      let hasPastDate = startDate.isAfter(today) && endDate.isAfter(today);
      setIsPastDate(!hasPastDate);
      let isTimeValid = true;
      if (startTime && endTime) {
        isTimeValid = endTime.isAfter(startTime);
      }
      setIsPastTime(!isTimeValid);
      return (
        !date.start_date ||
        !date.start_date.isValid() ||
        !date.end_date ||
        !date.end_date.isValid() ||
        !date.start_time ||
        !date.end_time ||
        !date.start_time.isValid() ||
        !date.end_time.isValid() ||
        (date.start_time &&
          date.end_time &&
          date.start_time.isSame(date.end_time)) ||
        !hasPastDate ||
        !isTimeValid
      );
    });
  }, [multipleDates, isStaff]);

  const isInvalidDate = useMemo(() => {
    let invalidDate = false;
    multipleDates.forEach((dates1, index1) => {
      const sDate1 = dates1.start_date;
      const eDate1 = dates1.end_date;
      const sTime1 = dates1.start_time;
      const eTime1 = dates1.end_time;
      if (sDate1 && eDate1 && sTime1 && eTime1) {
        multipleDates.forEach((dates2, index2) => {
          const sDate2 = dates2.start_date;
          const eDate2 = dates2.end_date;
          const sTime2 = dates2.start_time;
          const eTime2 = dates2.end_time;
          if (
            index1 !== index2 &&
            sDate2 &&
            eDate2 &&
            sTime2 &&
            eTime2 &&
            (sDate2.isBetween(sDate1, eDate1, 'day', '[]') ||
              eDate2.isBetween(sDate1, eDate1, 'day', '[]')) &&
            (sTime2.isBetween(sTime1, eTime1, 'minute', '[]') ||
              eTime2.isBetween(sTime1, eTime1, 'minute', '[]'))
          ) {
            invalidDate = true;
          }
        });
      }
    });
    return invalidDate;
  }, [multipleDates]);

  const isDuplicateOtherEvent = useMemo(() => {
    if (othersEventName.length > 0 && Array.isArray(allEvents)) {
      return allEvents
        .filter(x => x.event_name !== null)
        .some(o => {
          return o.event_name.toLowerCase() === othersEventName.toLowerCase();
        });
    }
    return false;
  }, [allEvents, othersEventName]);

  const isEventSportsAvail = useMemo(() => {
    if (events.some(obj => obj.id === 0) && !othersEventName) {
      return false;
    }
    return events.length;
  }, [events, othersEventName]);

  const isMessagesValid = useMemo(() => {
    if (
      update_id &&
      (messageStatus === 'Active' || messageStatus === 'Inactive')
    ) {
      return messages[0]?.name && messages[0]?.media_track;
    }
    return checkMessagesValid(messages);
  }, [messageStatus, messages, update_id]);

  const steps = useMemo(() => {
    const stepList = [
      {
        label: 'Select Options',
        disabled: false,
      },
      {
        label: 'Select Days',
        disabled: isGT
          ? !schools.length ||
            !stations.length ||
            !isEventSportsAvail ||
            isDuplicateOtherEvent
          : !stations.length,
      },
      {
        label: 'Message details',
        disabled:
          isInvalidDate || isEmptyDate || !multipleDates.length || !days.length,
      },
      {
        label: 'Review',
        disabled: !isMessagesValid,
      },
    ];

    return stepList;
  }, [
    isGT,
    schools.length,
    stations.length,
    isEventSportsAvail,
    isDuplicateOtherEvent,
    isInvalidDate,
    isEmptyDate,
    multipleDates.length,
    days.length,
    isMessagesValid,
  ]);

  const handleSubmit = useCallback(() => {
    setApiState({status: 'pending', error: null, successMsg: ''});
    const url = update_id
      ? `/v1/company/${companyId}/message/event-message/${update_id}?_method=PUT`
      : `/v1/company/${companyId}/message/event-message`;
    const baseReqIdToDelete = base_request_id ? base_request_id : null;
    const msgs = messages
      .filter(
        ({message, name, media_track}) =>
          (message || media_track) && name !== '',
      )
      .map(({message, name, media_track}) => ({
        message,
        name,
        media_track_id: media_track?.id ?? null,
      }));
    const multiple_dates = multipleDates.map(o => ({
      start_date: dayjs(o.start_date).format('YYYY-MM-DD'),
      end_date: dayjs(o.end_date).format('YYYY-MM-DD'),
      start_time: dayjs(o.start_time).format('HH:mm'),
      end_time: dayjs(o.end_time).format('HH:mm'),
      is_whole_day: o.is_whole_day,
    }));
    const stationIds = stations.map(v => v.id);
    const payload = {
      base_request_id: baseReqIdToDelete,
      station_ids: stationIds,
      messages: update_id ? msgs.slice(0, 1) : msgs,
      event_date_type: eventDateType,
      multiple_dates: multiple_dates,
      send_to_psm: false,
      week_days: daysOfWeek
        .filter(day => days.includes(day))
        .map(d => d.toLowerCase()),
    };

    if (isGT) {
      if (othersEventName) {
        Object.assign(payload, {others_event_name: othersEventName});
      }
      const schoolIds = schools.map(v => v.id);
      const sportIds = events.map(v => v.id).filter(id => id !== 0);
      if (sportIds.length) {
        Object.assign(payload, {event_type_ids: sportIds});
      }
      Object.assign(payload, {school_ids: schoolIds});
    }
    apiPost(url, payload)
      .then(res => {
        setApiState({
          status: 'success',
          error: null,
          successMsg: res.data.message,
        });
        queryClient.invalidateQueries({
          queryKey: [eventSpecificDataGridApiPath(companyId)],
          refetchType: 'active',
        });
        if (base_request_id) {
          setTimeout(() => {
            navigate(0);
          }, 1000);
        }
      })
      .catch(error => {
        setApiState({status: 'error', error, successMsg: ''});
      });
  }, [
    update_id,
    companyId,
    base_request_id,
    messages,
    multipleDates,
    stations,
    eventDateType,
    isGT,
    days,
    othersEventName,
    schools,
    events,
    queryClient,
    navigate,
  ]);

  const handleOtherEvents = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setOthersEventName(e.target.value);
    },
    [],
  );

  useEffect(() => {
    if (eventDateType === 2) {
      setSavedOldDays(days);
    }
  }, [days, eventDateType]);

  useEffect(() => {
    if (selectedTracks.length) {
      if (update_id) {
        setMessages(messages =>
          updateTrackToMessage({
            messages,
            track: selectedTracks[0] as IMediaTrack,
          }),
        );
      } else {
        setMessages(messages =>
          addNewTrackToMessages({
            messages,
            requestable_type: 'event-message',
            tracks: selectedTracks,
          }),
        );
      }
    }
  }, [selectedTracks, update_id]);

  return (
    <StepperLayout
      steps={steps}
      activeStepIndex={activeStepIndex}
      onBack={onPreviousStep}
      onNext={onNextStep}
      onCancel={handleClose}
      onSubmit={handleSubmit}
      onStepClick={onStepClick}
      loading={apiState.status === 'pending'}
      isSubmitted={apiState.status !== ''}
      title='Create New Message'>
      {(() => {
        const label = steps?.[activeStepIndex]?.label ?? '';
        switch (label) {
          case 'Select Options':
            return (
              <Box>
                <Typography
                  variant='h5'
                  className='text-white font-bold mb-6 mt-2'>
                  Select Options
                </Typography>
                <Box>
                  {isGT && (
                    <SchoolsDropdownGetSchools
                      selected={schools}
                      setSelected={setSchools}
                    />
                  )}
                  <StationsDropDown
                    stations={stations}
                    setStations={setStations}
                    fetchEnable={true}
                  />
                  {isGT ? (
                    <EventsDropdownGetEvents
                      selected={events}
                      setSelected={setEvents}
                      setEvents={setAllEvents}
                      othersEventName={othersEventName}
                      handleOtherEvents={handleOtherEvents}
                      isDuplicateOtherEvent={isDuplicateOtherEvent}
                    />
                  ) : null}
                </Box>
              </Box>
            );
          case 'Select Days':
            return (
              <EsmcDatesTimes
                invalidDate={isInvalidDate}
                event_date_type={eventDateType}
                setEventDateType={setEventDateType}
                multiple_dates={multipleDates}
                setMultipleDates={setMultipleDates}
                isPastTime={isPastTime}
                isPastDate={isPastDate}
                days={days}
                setDays={setDays}
                savedOldDays={savedOldDays}
              />
            );
          case 'Message details':
            return (
              <>
                {isStaff &&
                (!messageStatus || messageStatus === 'In Progress') ? (
                  <Box textAlign='end'>
                    <ExistingCampaignChooserBtn
                      label='Choose Event Specific Message'
                      type='event-message'
                      max={update_id ? 1 : 50}
                      tracks={selectedTracks}
                      onChange={setSelectedTracks}
                      companyId={companyId}
                    />
                  </Box>
                ) : null}
                <EsmMessages
                  messages={messages}
                  setMessages={setMessages}
                  setSelectedTracks={setSelectedTracks}
                  requestableType='event-message'
                  isUpdate={Boolean(update_id)}
                  messageStatus={messageStatus}
                />
              </>
            );
          case 'Review':
            return (
              <EsmReview
                other_event_name={othersEventName}
                schools={schools}
                events={events}
                stations={stations}
                multiple_dates={multipleDates}
                messages={messages}
                from='form'
                days={days}
              />
            );
          default:
            return null;
        }
      })()}
      {apiState.status !== '' && (
        <DialogApiRequestStatus
          onEdit={() => setApiState(defaultApiState)}
          onRetry={handleSubmit}
          onClose={handleClose}
          apiState={apiState}
        />
      )}
    </StepperLayout>
  );
};

export default EventSpecificStepper;
