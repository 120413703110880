import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {IScripts} from './event-specific/EsmMessages';
import MusicPreviewBtn from '../../components/music/MusicPreviewBtn';
import DownloadButton from '../../components/Button/DownloadButton';

interface SectionData {
  id: number; // Replace `number` with the actual type of id
  name: string; // Replace `string` with the actual type of name
  station_name?: string;
}

export interface messageReviewProps {
  sectionTitle: string;
  sectionArrayData: SectionData[];
  showOnlyName?: boolean;
}

export interface messageSingleProps {
  sectionTitle: string;
  sectionContent: string;
}

export interface messageTableProps {
  sectionTitle: string;
  messages: IScripts[];
}

export const MessageReviewLine = ({
  sectionTitle,
  sectionArrayData,
  showOnlyName = false,
}: messageReviewProps) => {
  return (
    <Box>
      {sectionArrayData.length > 0 && (
        <TableContainer>
          <TableRow>
            <TableCell width='200'>
              <Typography className='text-white-50'>{sectionTitle}</Typography>
            </TableCell>
            <TableCell>
              <Typography>
                {sectionArrayData.map((item, index: number) => (
                  <span key={item.id} id={sectionTitle}>
                    <span>
                      {item.name}
                      {!showOnlyName &&
                        (item.station_name ? '-' + item.station_name : '')}
                    </span>
                    {index !== sectionArrayData.length - 1 && <span>, </span>}
                  </span>
                ))}
              </Typography>
            </TableCell>
          </TableRow>
        </TableContainer>
      )}
    </Box>
  );
};

export const MessageSingleReviewLine = ({
  sectionTitle,
  sectionContent,
}: messageSingleProps) => {
  return (
    <>
      <TableContainer>
        <TableRow>
          <TableCell width='200'>
            <Typography className='text-white-50' id={sectionTitle}>
              {sectionTitle}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>{sectionContent}</Typography>
          </TableCell>
        </TableRow>
      </TableContainer>
    </>
  );
};

export const MessageReviewTable = ({
  sectionTitle,
  messages,
}: messageTableProps) => {
  return (
    <Box className='w-full'>
      <Typography variant='h6' className='text-white font-bold mt-5'>
        {sectionTitle}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className='w-80'>Play</TableCell>
              <TableCell className='w-200'>Message Name</TableCell>
              <TableCell>Message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messages.map(
              row =>
                row.message !== '' &&
                row.name !== '' && (
                  <TableRow key={row.uuid}>
                    <TableCell id={`playlist-${row.uuid}`}>
                      {row.media_track ? (
                        <Stack direction='row' spacing={2}>
                          <MusicPreviewBtn
                            id={row.media_track.id}
                            url={row.media_track.url}
                          />
                          <DownloadButton
                            filename={row.media_track.track_name}
                            url={row.media_track.url}
                          />
                        </Stack>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell id={`message-name-${row.uuid}`}>
                      {row.name}
                    </TableCell>
                    <TableCell id={`message-describtion-${row.uuid}`}>
                      <pre>{row.message}</pre>
                    </TableCell>
                  </TableRow>
                ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
