import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {useCallback, useEffect, useMemo, useState} from 'react';
import IconWarning from '../../../asset/icons/icon_warning.svg';
import {IMediaTrack} from '../../../dto/MediaTrack.dto';
import {
  IWalkupPlayerType,
  useRqWalkupRequestShowByToken,
} from '../../../react_query/WalkupRequest/RqWalkupRequestShow';
import {apiPost} from '../../../axiosConfig';
import Toast from '../../../package/Toaster';
import {VerifyStudentToken} from '../../../react_query/music_selections/studentSelections/VerifyStudentToken';
import {WalkupMusicSelectionTable} from './WalkupMusicSelectionTable';

const WalkupStudentSelectionDialog = () => {
  const {token} = useParams();
  const [formPlayersId, setformPlayersId] = useState([0]);
  const [players, setPlayers] = useState<IWalkupPlayerType[]>([]);
  const [playerId, setPlayerId] = useState<number>(0);
  const navigate = useNavigate();

  const {data, isFetching} = useRqWalkupRequestShowByToken(token ?? '');

  const onSelect = useCallback(
    (track: IMediaTrack) => {
      setPlayers(prev => {
        if (playerId) {
          return prev.map(o => {
            if (o.id === playerId) {
              return {
                ...o,
                file: null,
                media_track_id: track.id,
                track,
                track_artist: track.track_artist,
                track_name: track.track_name,
              };
            }
            return o;
          });
        }
        return prev;
      });
    },
    [playerId, setPlayers],
  );

  const onDeSelect = useCallback(
    (_track: IMediaTrack) => {
      setPlayers(prev => {
        if (playerId) {
          return prev.map(o => {
            if (o.id === playerId) {
              delete o.track;
              return {
                ...o,
                file: null,
                media_track_id: null,
                track_artist: '',
                track_name: '',
              };
            }
            return o;
          });
        }
        return prev;
      });
    },
    [playerId, setPlayers],
  );

  const track = useMemo(() => {
    const track = players.find(o => o.id === playerId)?.track;
    if (track) {
      return [track];
    }
    return [];
  }, [playerId, players]);

  useEffect(() => {
    if (!isFetching && data) {
      setformPlayersId(data.form_student_ids ?? []);
      setPlayers(
        data.players.map(val => {
          if (val.track) {
            return {...val, isPlayerDisabled: true};
          }
          return {...val, isPlayerDisabled: false};
        }) ?? [],
      );
    }
  }, [data, isFetching]);

  const submit = useCallback(() => {
    const fd = new FormData();
    if (data?.name) {
      fd.append(`name`, data?.name);
    }
    fd.set(`stations[0]`, `${data?.station_id ?? 0}`);

    if (players.length) {
      players.forEach((item, i) => {
        fd.append(`players[${i}][id]`, `${item.id}`);
        fd.append(`players[${i}][track_artist]`, `${item.track_artist}`);
        fd.append(`players[${i}][track_name]`, `${item.track_name}`);
        fd.set(`players[${i}][player_no]`, `${item.player_no}`);
        fd.set(`players[${i}][first_name]`, `${item.first_name}`);
        fd.set(`players[${i}][last_name]`, `${item.last_name}`);
        if (item.media_track_id) {
          fd.append(`players[${i}][media_track_id]`, `${item.media_track_id}`);
        } else if (item.file) {
          fd.append(`players[${i}][file]`, item.file);
        }
        if (item.id > 0) {
          if (item.file) {
            fd.append(`players[${i}][type]`, `change`);
          } else {
            const value = data?.players.find(o => o.id === item.id);
            if (value && value.media_track_id !== item.media_track_id) {
              fd.append(`players[${i}][type]`, `change`);
            }
          }
        }
      });
    }

    const submitUrl = `/v1/walkup-student-form/${token}`;
    apiPost(submitUrl, fd)
      .then(() => {
        Toast('Student Selection Form Submitted', 'success');
        navigate(`/walkup-student-selection/${token}/success-msg`);
      })
      .catch(error => {
        console.log(error);
      });
  }, [players, data?.players, data?.name, data?.station_id, token, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      const verifyStatus = await VerifyStudentToken(token ?? '', 'walkup');
      if (verifyStatus !== 200)
        navigate(`/walkup-student-selection/${token}/error-msg`);
    };
    fetchData();
  }, [navigate, token]);

  return (
    <Dialog open fullWidth maxWidth='xl'>
      <DialogTitle textAlign='center'>
        <Typography className='text-center font-bold'>
          STUDENT SELECTION FORM
        </Typography>
      </DialogTitle>
      <DialogContent sx={{padding: '0 !important'}}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              background: `var(--blue_variant36)`,
            }}>
            <Box className='pt-8 pb-5 px-8 h-80'>
              <Typography variant='h6' className='font-bold mr-8'>
                Select Player
              </Typography>
            </Box>
            <Box
              className='flex-column scrollbar h-full overflow-auto justify-between'
              sx={{maxHeight: 'calc(100% - 80px) !important'}}>
              <List className='p-0 mb-2 list-items-sec h-500 overflow-auto'>
                {players
                  .filter(val => formPlayersId.includes(val.id))
                  .map(o => (
                    <ListItem
                      disablePadding
                      key={o.id}
                      secondaryAction={
                        !o.track_artist && !o.track_name ? (
                          <img
                            src={IconWarning}
                            alt='New Song Request Icon'
                            className='w-26 h-32 pt-1'
                          />
                        ) : null
                      }>
                      <ListItemButton
                        disabled={o.isPlayerDisabled}
                        onClick={() => setPlayerId(o.id)}
                        selected={o.id === playerId}>
                        <ListItemText
                          className='ellipsis'
                          primary={`#${o.player_no} ${o.first_name} ${o.last_name}`}
                          secondary={o.track_artist}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
            </Box>
          </Grid>
          <Grid item xs={12} md={9} className='pt-6 pb-8 px-9'>
            <WalkupMusicSelectionTable
              selectedTracks={track}
              onSelect={onSelect}
              onDeselect={onDeSelect}
            />
            <Stack
              className='flex justify-center items-center mt-2'
              direction='row'
              spacing={2}>
              <Button variant='contained' onClick={submit}>
                Submit
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default WalkupStudentSelectionDialog;
