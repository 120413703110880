import {Alert, AlertTitle, Box, Typography} from '@mui/material';
import {useStylesed} from '../../../components/styles/commonStyled';

const StudentMusicSelectionSuccessPage = ({
  title,
  message = 'Something went wrong',
  severity = 'error',
}: {
  title?: string;
  message: string;
  severity: 'error' | 'info' | 'success' | 'warning';
}) => {
  const classes = useStylesed();
  return (
    <Box className={classes.r_center} sx={{height: '100%'}}>
      <Alert severity={severity}>
        {title ? (
          <AlertTitle>
            <Typography fontWeight={800}>{title}</Typography>{' '}
          </AlertTitle>
        ) : null}
        {message}
      </Alert>
    </Box>
  );
};

export default StudentMusicSelectionSuccessPage;
