import {Alert} from '@mui/material';

export const getAudioDurationFromUrl = (url: string): Promise<number> => {
  return new Promise((resolve, reject) => {
    const audio = document.createElement('audio');
    audio.src = url;
    audio.addEventListener('loadedmetadata', () => {
      resolve(audio.duration);
    });

    audio.addEventListener('error', () => {
      reject(0);
    });
  });
};

export const getAudioDurationFromFile = (file: File) => {
  const url = URL.createObjectURL(file);
  return getAudioDurationFromUrl(url);
};

const AudioDurationValidation = ({
  maxDurationInMin,
  durationInSec,
}: {
  maxDurationInMin: number;
  durationInSec: number;
}) => {
  if (durationInSec / 60 >= maxDurationInMin) {
    return (
      <Alert severity='error' sx={{my: 1}}>
        Max Song Duration {maxDurationInMin} minutes
      </Alert>
    );
  }
  return null;
};

export default AudioDurationValidation;
