import {AxiosError, AxiosResponse} from 'axios';
import {useQuery} from '@tanstack/react-query';
import Toast from '../../../package/Toaster';
import {IApiPaginationResponse} from '../../../dto/API.dto';
import axiosInstance from '../../../axiosConfig';
import {IMediaTrack} from '../../../dto/MediaTrack.dto';

export const getListMusicsInGenresByStudentTokenApiPath = (token: string) =>
  `/v1/music-selection/student-form/${token}/track`;

const getMusicsInGenresByStudentToken = (
  token: string,
  params: IStudentMusicSelectQueryParms = {per_page: 10},
) => {
  return axiosInstance
    .get(getListMusicsInGenresByStudentTokenApiPath(token), {
      params,
    })
    .then((response: AxiosResponse<IApiPaginationResponse<IMediaTrack[]>>) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      Toast('Error in listing musics', 'error');
      throw error;
    });
};

export type IStudentMusicSelectQueryParms = {
  search?: string;
  starts_with?: string;
  page?: number;
  per_page: number;
  track_name?: string;
  track_artist?: string;
};

export const useRqListMusicsInGenresByStudentToken = (
  token: string,
  params: IStudentMusicSelectQueryParms = {per_page: 10},
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [getListMusicsInGenresByStudentTokenApiPath(token), params],
    queryFn: () => getMusicsInGenresByStudentToken(token, params),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled,
  });
};
