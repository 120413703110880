import {AxiosError, AxiosResponse} from 'axios';
import axiosInstance from '../../../axiosConfig';
import {AxiosErrorToast} from '../../general/ReusableFunctions';

export const VerifyStudentToken = async (
  token: string,
  type: string = 'music-selection',
) => {
  const url =
    type === 'music-selection'
      ? `/v1/music-selection/student-form/${token}/verify`
      : `v1/walkup-student-form/${token}/verify`;
  return axiosInstance
    .post(url)
    .then((response: AxiosResponse) => {
      return response.status;
    })
    .catch((error: AxiosError) => {
      AxiosErrorToast(error, 'Token is invalid');
      return error.response?.status;
    });
};
