import {AxiosError, AxiosResponse} from 'axios';
import axiosInstance from '../../../axiosConfig';
import {IApiSuccessMsgResponse} from '../../../dto/API.dto';
import {AxiosErrorToast} from '../../general/ReusableFunctions';

export interface IStoreStudentSelectedSongs {
  name: string;
  selected_songs: {
    media_track_id: number;
    track_artist: string;
    track_name: string;
  }[];
}

export const StoreStudentSelectedSongs = async (
  token: string,
  payload: IStoreStudentSelectedSongs,
) => {
  return axiosInstance
    .post(`/v1/music-selection/student-form/${token}`, payload)
    .then((response: AxiosResponse<IApiSuccessMsgResponse>) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      AxiosErrorToast(error, 'Error in submiting songs selected');
      throw error;
    });
};
