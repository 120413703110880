import {useCallback, useState} from 'react';
import {Button, IconButton, Stack, styled} from '@mui/material';
import IconAddSong from '../../asset/icons/icon_addsong.svg';
import {Warning} from '@mui/icons-material';
import {getAudioDurationFromFile} from '../utlis/Audio/GetAudioDuration';

const UploadButton = ({
  label = 'Upload File',
  handleChange,
  disabled = false,
}: {
  label?: string;
  handleChange: (value: string | File | undefined, field: 'files') => void;
  disabled?: boolean;
}) => {
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  const [fileDuration, setFileDuration] = useState(0);

  const handleFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (files && files.length > 0) {
        const file = files[0] as File;
        const duration = await getAudioDurationFromFile(file);
        setFileDuration(duration);
        if (duration / 60 <= 5) {
          handleChange(file, 'files');
        }
      }
    },
    [handleChange],
  );

  return (
    <Stack direction='row' alignItems='center'>
      <Button
        variant='contained'
        color='primary'
        disabled={disabled}
        component='label'
        startIcon={<img src={IconAddSong} alt='Create Task Icon' />}>
        {label}
        <VisuallyHiddenInput
          type='file'
          accept='audio/*'
          onChange={handleFileChange}
        />
      </Button>
      {fileDuration / 60 >= 5 && (
        <IconButton color='warning' title='Max Song Duration 5 minutes'>
          <Warning />
        </IconButton>
      )}
    </Stack>
  );
};

export default UploadButton;
