import {Route, Routes} from 'react-router-dom';
import StudentMusicSelection from '../../pages/music/student-music-selection/MusicSelectionForm';
import StudentMusicSelectionSuccessPage from '../../pages/music/student-music-selection/MusicSelectionSuccessPage';
import WalkupStudentSelectionDialog from '../../pages/music/walkup/WalkupStudentSelectionForm';

const StudentSelectionRoutes = () => {
  return (
    <Routes>
      <Route path=':token'>
        <Route index element={<StudentMusicSelection />} />
        <Route
          path='success-msg'
          element={
            <StudentMusicSelectionSuccessPage
              title='Thank You.'
              message='Your submission has been completed successfully.'
              severity='success'
            />
          }
        />
        <Route
          path='error-msg'
          element={
            <StudentMusicSelectionSuccessPage
              message='The Student Selection Form URL has expired.'
              severity='error'
            />
          }
        />
      </Route>
    </Routes>
  );
};

export default StudentSelectionRoutes;

export const WalkupStudentSelectionRoutes = () => {
  return (
    <Routes>
      <Route path=':token'>
        <Route index element={<WalkupStudentSelectionDialog />} />
        <Route
          path='success-msg'
          element={
            <StudentMusicSelectionSuccessPage
              title='Thank You.'
              message='Your submission has been completed successfully.'
              severity='success'
            />
          }
        />
        <Route
          path='error-msg'
          element={
            <StudentMusicSelectionSuccessPage
              message='The Student Selection Form URL has expired.'
              severity='error'
            />
          }
        />
      </Route>
    </Routes>
  );
};
