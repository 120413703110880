import {AxiosError, AxiosResponse} from 'axios';
import {useQuery} from '@tanstack/react-query';
import Toast from '../../../package/Toaster';
import {IApiDataResponse} from '../../../dto/API.dto';
import axiosInstance from '../../../axiosConfig';
import {ICompany} from '../../../dto/Company.dto';

export const getUnauthStudentFormDetailsApiPath = (token: string) =>
  `/v1/music-selection/student-form/${token}/details`;

const getUnauthStudentFormDetailsByStudentToken = (token: string) => {
  return axiosInstance
    .get(getUnauthStudentFormDetailsApiPath(token))
    .then(
      (
        response: AxiosResponse<
          IApiDataResponse<{
            id: number;
            company: ICompany;
            song_count: number;
            expiry_days: number;
            expiry_date: string;
            link_status: number;
            comment: string;
          }>
        >,
      ) => {
        return response.data.data;
      },
    )
    .catch((error: AxiosError) => {
      Toast('Error in gettting student form details', 'error');
      throw error;
    });
};

export const useRqUnauthStudentFormDetailsByStudentToken = (
  token: string,

  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [getUnauthStudentFormDetailsApiPath(token)],
    queryFn: () => getUnauthStudentFormDetailsByStudentToken(token),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled,
  });
};
